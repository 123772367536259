// src/components/molecules/LoginForm.js
import React, { useState } from 'react';
import { Button } from "../atoms/Button";
import { Form } from "../atoms/Form";
import { Input, SpacedInput } from "../atoms/Input";
import { CenteredContainer } from '../atoms/CenteredContainer';
import { CenteredHeader } from '../atoms/CenteredHeader';
import { signIn } from '../../api/auth';
import { getUserInfo } from '../../api/user';

const LoginForm = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState('');

const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const data = await signIn(nickname, password);
        // Save the tokens in local storage
        localStorage.setItem('accessToken', data.token.access_token);
        localStorage.setItem('refreshToken', data.token.refresh_token);
        localStorage.setItem('userId', data.id);

        const userData = await getUserInfo(data.id);

        localStorage.setItem('role', userData.role);

        if (userData.role !== 'owner') {
            throw new Error('Нет прав модерации');
        }
        
        onSubmit({ nickname, password });
    } catch (error) {
        setError('Failed to log in: ' + error);
        console.log("Failed to log in: ", error);
    }
};

  return (
    <CenteredContainer>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CenteredHeader>Login</CenteredHeader>
            {error && <p>{error}</p>} {/* Display the error message */}
            <Form onSubmit={handleSubmit}>
                <SpacedInput type="text" placeholder={"Nickname"} value={nickname} onChange={e => setNickname(e.target.value)}/>
                <Input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
                <Button type="submit">Log in</Button>
            </Form>
        </div>
    </CenteredContainer>
  );
};

export default LoginForm;
