// src/components/atoms/ButtonLink.js
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Create a styled button-link component
export const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1.2em;
  text-decoration: none;
  color: inherit;
  border: 1px solid;
`;
