// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import DashboardPage from './pages/Dashboard';
import UserBalance from './pages/UserBalance';
import UnverifiedTasks from './pages/UnverifiedTasks';

function App() {
  const isAuthenticated = localStorage.getItem('accessToken');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/" />} />
        <Route path="/user-balance" element={isAuthenticated ? <UserBalance /> : <Navigate to="/" />} />
        <Route path="/unverified-tasks" element={isAuthenticated ? <UnverifiedTasks /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
