import styled from 'styled-components';

export const Form = styled.form`
  background-color: white;
  max-width: 415px;
  width: 100%;
  border: 3.5px solid #003DCC;
  padding: 15px 30px;
  height: fit-content;
  border-radius: 7px;
  color: #003DCC;
`;
