// Import the styled-components library
import styled from 'styled-components';

// Create a styled header component
export const CenteredHeader = styled.h1`
  text-align: center;
  width: 100%;
`;

export const CenteredHeader2 = styled.h2`
  text-align: center;
  width: 100%;
`;
