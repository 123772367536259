// src/user.js
import api from './config';

export const getUserInfo = async (userId) => {
  const response = await api.get("/user/info/" + userId);
  console.log(response.data);
  return response.data;
};

export const giveCoins = async (userId, amount) => {
  const token = localStorage.getItem('accessToken');
  console.log(`Paying ${amount} coins to user ${userId}`)
  const response = await api.post('/transactions/give_coins', { id: userId, amount: parseInt(amount) }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
  });
  return response.data;
};
