// src/components/organisms/DashboardPage.js
import React from 'react';
import { CenteredContainer } from '../components/atoms/CenteredContainer';
import Dashboard from '../components/molecules/DashBoard';
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('accessToken');

    if (!isAuthenticated) {
        navigate('/login');
        return null;
    }

    return (
        <CenteredContainer>
            <Dashboard />
        </CenteredContainer>
    );
};

export default DashboardPage;
