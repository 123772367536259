// src/auth.js
import api from './config';

export const signIn = async (login, password) => {
  const response = await api.post('/auth/sign-in', { login, password });
  return response.data;
};

export const refresh = async (refreshToken) => {
  const response = await api.post('/auth/refresh', { refresh_token: refreshToken });
  return response.data;
};
