// src/components/molecules/Dashboard.js
import React from 'react';
import { ButtonLink } from '../atoms/ButtonLink';
import { CenteredHeader } from '../atoms/CenteredHeader';

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CenteredHeader>Дашборд</CenteredHeader>
      <ButtonLink to="/user-balance">Пополнить баланс пользователя</ButtonLink>
      <ButtonLink to="/unverified-tasks">Посмотреть неподтвержденные задачи</ButtonLink>
    </div>
  );
};

export default Dashboard;
