import { Button, RedButton, PurpleButton } from '../atoms/Button';
import { CenteredParagraph } from '../atoms/CenteredParagraph';
import { SpacedTextArea } from '../atoms/TextArea';
import { CenteredHeader2 } from '../atoms/CenteredHeader';
import { giveCoins } from '../../api/user';
import React, { useState } from 'react';

const Task = ({ task, index, setTasks, verifyTask, deleteUnverifiedTask }) => {
    const [error, setError] = useState(null);
    const [coins, setCoins] = useState(0);

    const payCoins = async (userId, amount) => {
        try {
            await giveCoins(userId, amount);
            console.log(`Paid ${amount} coins to user ${userId}`);
            setError("Коины переведены!")
        } catch (error) {
            setError(error)
            console.error('Ошибка при отправке монет:', error);
        }
    };

    return (
        <div key={index}>
            <CenteredHeader2>{task.name}</CenteredHeader2>
            <CenteredParagraph><a href={`https://code-bank.ru/account/${task.author}`}>Автор</a></CenteredParagraph>
            {error && <p>{error}</p>}
            <div style={{ textAlign: "center" }}>
                <CenteredParagraph><a href={`https://code-bank.ru/tasks/${task.id}`}>Ссылка на задачу</a></CenteredParagraph>
                <SpacedTextArea type="text" placeholder="Enter comment" onChange={(e) => setTasks(prevTasks => {
                    const updatedTasks = [...prevTasks];
                    updatedTasks[index].comment = e.target.value;
                    return updatedTasks;
                })} />
                <input type="number" value={coins} onChange={(e) => setCoins(e.target.value)} min={0} max={1000} />
            </div>
            <Button onClick={() => payCoins(task.author, coins)}>Заплатить</Button>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => verifyTask(task.id, true, task.comment)}>Принять</Button>
                <PurpleButton onClick={() => verifyTask(task.id, false, task.comment)}>Отклонить</PurpleButton>
                <RedButton onClick={() => deleteUnverifiedTask(task.id)}>Удалить</RedButton>
            </div>
        </div>
    );
};

export default Task;
