import Task from '../molecules/Task';

const TaskList = ({ tasks, setTasks, verifyTask, deleteUnverifiedTask }) => {
    return (
      <div>
        {Array.isArray(tasks) && tasks.map((task, index) => (
          <Task 
            key={index} 
            task={task} 
            index={index} 
            setTasks={setTasks} 
            verifyTask={verifyTask} 
            deleteUnverifiedTask={deleteUnverifiedTask} 
          />
        ))}
      </div>
    );
};

export default TaskList;
