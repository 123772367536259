// src/pages/Login.js
import React from 'react';
import LoginForm from '../components/molecules/LoginForm';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const handleLogin = (credentials) => {
    // For now, any login with any password is correct
    navigate('/dashboard');
  };

  return (
    <div>
      <LoginForm onSubmit={handleLogin}/>
    </div>
  );
};

export default Login;
