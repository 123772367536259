// src/components/atoms/Input.js
import styled from 'styled-components';

export const TextArea = styled.textarea`
    color: black;
    font-size: 14px;
    line-height: 16px;
    height: 45px;
    width: 100%; // Adjust this value to make the TextArea wider
    border: 3px solid #003DCC;
    border-radius: 7px;
    padding-left: 10px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
        border: 2px solid #003DCC;
    }
`;

export const SpacedTextArea = styled(TextArea)`
  margin-bottom: 20px;
`;
