// src/components/atoms/Button.js
import styled from 'styled-components';

export const Button = styled.button`
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 18px;
  border: none;
  min-width: 200px;
  height: 50px;
  display: block;
  background-color: #195EFF;
  border-radius: 5px;
  transition: all 0.2s ease 0s;
  color: white;
  line-height: 15px;
  cursor: pointer;

  &:hover {
    background-color: #4770ed;
  }
`;

export const RedButton = styled(Button)`
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;

export const PurpleButton = styled(Button)`
  background-color: purple;

  &:hover {
    background-color: darkpurple;
  }
`;
