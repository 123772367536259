import React, { useEffect, useState } from 'react';
import { getAllUnverifiedTasks, deleteUnverifiedTask, verifyTask } from '../api/tasks';
import { CenteredHeader } from '../components/atoms/CenteredHeader';
import TaskList from '../components/organisms/TaskList'; // import the TaskList component

const UnverifiedTasks = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const data = await getAllUnverifiedTasks();
      setTasks(data.tasks);
    }

    fetchTasks();
  }, []);

  return (
    <div>
      <CenteredHeader>Задачи, требующие модерации</CenteredHeader>
      <TaskList 
        tasks={tasks} 
        setTasks={setTasks} 
        verifyTask={verifyTask} 
        deleteUnverifiedTask={deleteUnverifiedTask} 
      />
    </div>
  );
};

export default UnverifiedTasks;