// src/components/atoms/Input.js
import styled from 'styled-components';

export const Input = styled.input`
  color: black;
  font-size: 14px;
  line-height: 16px;
  height: 45px;
  width: 100%;
  border: 3px solid #003DCC;
  border-radius: 7px;
  padding-left: 10px;

  &:focus {
    outline: none;
    border: 2px solid #003DCC;
  }
`;

export const SpacedInput = styled(Input)`
  margin-bottom: 20px;
`;
