// src/user.js
import api from './config';

export const getAllUnverifiedTasks = async () => {
    const token = localStorage.getItem('accessToken');
    const response = await api.get('/tasks/unverified', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const deleteUnverifiedTask = async (taskId) => {
  const token = localStorage.getItem('accessToken');
  const response = await api.delete('/task/' + taskId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
  });
  return response.data;
};

export const verifyTask = async (taskId, verify, comment) => {
  const token = localStorage.getItem('accessToken');
  const response = await api.post('/task/' + taskId + '/verify', {
    is_verified: verify,
    comment: comment
  }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
  });
  return response.data;
}
